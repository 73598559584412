<template lang="pug">
    div
        v-snackbar(
            :timeout="3000",
            :top="true"
            :right="true"
            :color="manageColor"
            v-model="manageMsj")
                | {{ manageText }}
                v-btn(flat dark @click.native="manageMsj = false") {{ $t('cerrar') }}
        v-dialog(v-if="modal" v-model="modal" max-width="600" persistent)
            v-form
                v-card
                    v-card-text(v-if="preload")
                       h3 {{ $t('Cargando...') }}
                    template(v-else)
                        v-card-title
                            .btns.btns-right
                                v-btn(icon color="secondary" small @click="close")
                                    v-icon close
                            | {{ $t('Editar usuario') }}
                        v-card-text
                            v-layout(row wrap)
                                v-flex(xs12)
                                    v-text-field(v-model='model.names' :label='$t("Nombre(s)")' required)
                                v-flex(xs12)
                                    v-text-field(v-model='model.surname' :label='$t("Primer apellido")' required)
                                v-flex(xs12)
                                    v-text-field(v-model='model.surname2' :label='$t("Segundo apellido")')
                                v-flex(xs12 v-if="hasMexico && $root.profile.type == 1")
                                    v-text-field(v-model.trim='model.username' :label='$t("ID")')
                                v-flex(xs12)
                                    v-text-field(v-model.trim="model.email" :label="$t('Correo electrónico')" type="email" required)
                                v-flex(xs12)
                                    v-select(
                                        v-model="model.countryid"
                                        :items='country'
                                        item-value="uuid"
                                        item-text="name"
                                        :label='$t("País")'
                                        append-icon="keyboard_arrow_down"
                                        autocomplete
                                        :filter="vFilter"
                                        required
                                        @input="model.stateid='',state=[],model.cityid='',city=[],model.zones=[],zones=[],zone=-1,fetchState()"
                                    )
                                v-flex(xs12)
                                    v-select(
                                        v-model="model.stateid"
                                        :items='state',
                                        item-value="uuid"
                                        item-text="name"
                                        :label='$t("Estado/Departamento/Provincia")'
                                        append-icon="keyboard_arrow_down"
                                        autocomplete
                                        :filter="vFilter"
                                        required
                                        @input="model.cityid='',city=[],model.zones=[],zones=[],zonei=-1,fetchCity()"
                                    )
                                v-flex(xs12)
                                    v-select(
                                        v-model="model.cityid"
                                        :items='city'
                                        item-value="uuid"
                                        item-text="name"
                                        :label='$t("Ciudad/Municipio")'
                                        append-icon="keyboard_arrow_down"
                                        autocomplete
                                        :filter="vFilter"
                                        required
                                        @input="model.zones=[],zones=[],zonei=-1,fetchZone()"
                                    )
                                template(v-if="zones.length")
                                    v-flex(xs12 v-for="(zone, i) in zones" :key="'z'+i")
                                        v-select(
                                            v-model="model.zones[i]"
                                            :items='zone'
                                            item-value="uuid"
                                            item-text="name"
                                            :label='zone[0].division'
                                            append-icon="keyboard_arrow_down"
                                            autocomplete
                                            :filter="vFilter"
                                            :required="isRequired('zones')"
                                            @change="zonei=i"
                                            @input="fetchZone"
                                        )
                                v-flex(xs12)
                                    v-text-field(:prefix="phone" v-model.trim='model.phone' :label='$t("Celular (WhatsApp)")' required)
                                v-flex(xs12)
                                    v-select(
                                        v-model="model.gender"
                                        :items='gender'
                                        item-value="id"
                                        item-text="text"
                                        :label='$t("Género")'
                                        append-icon="keyboard_arrow_down"
                                        autocomplete
                                        :filter="vFilter"
                                        required
                                    )
                                v-flex(xs12)
                                    v-dialog(ref='dialogBirthdate' persistent v-model='modalBirthdate' lazy full-width width='290px' :return-value.sync='model.birthdate')
                                        v-text-field(slot='activator' :label='$t("Fecha de nacimiento")' v-model='model.birthdate' readonly hide-details)
                                        v-date-picker(
                                            :locale="$root.currentLanguage" 
                                            v-model='model.birthdate' 
                                            ref="birthdate" 
                                            scrollable
                                            :min="minBirthdateDate"
                                            :max="maxBirthdateDate"
                                        )
                                            v-spacer
                                            v-btn(flat color='primary' @click='modalBirthdate = false') {{ $t('Cancelar') }}
                                            v-btn(flat color='primary' @click='$refs.dialogBirthdate.save(model.birthdate)') {{$t('OK')}}
                                v-flex(xs12)
                                    v-text-field(v-model='model.document' :label='$t("Número de documento de identificación")')

                                template(v-if="customForm.length")
                                    v-flex(xs12 v-for="(c, i) in customForm" :key="'c'+i")
                                        v-text-field(v-if="c.type == 1" v-model="model.custom[c.uuid]" :label="$t(c.placeholder)" type="text" :required="c.required")
                                        v-text-field(multi-line v-if="c.type == 5" v-model="model.custom[c.uuid]" :label="$t(c.placeholder)" type="text" :required="c.required")
                                        template(v-if="c.type == 2")
                                            label.d-block.mb-1 {{ c.placeholder }}{{c.required ? '&bull;':''}}
                                            v-checkbox(v-for="(opt, i) in c.options" :key="'opt'+i" v-model="model.custom[c.uuid]" :label="(typeof opt == 'string' ? opt : opt.option)" :value="(typeof opt == 'string' ? opt : opt.option)")
                                        template(v-if="c.type == 4")
                                            v-select(clearable v-if="c.depends == null ? true : !c.depends.length" v-model="model.custom[c.uuid]" :return-object="true" :items="c.options" item-value="uuid" item-text="option" :label="c.placeholder" autocomplete :filter="vFilter" append-icon="keyboard_arrow_down" :required="c.required" @input="computedDepends(c, $event)")
                                            v-select(clearable v-else v-model="model.custom[c.uuid]" :items="c.hasOwnProperty('coptions') ? c.coptions : []" :required="c.required" item-value="uuid" item-text="option" :label="c.placeholder" :return-object="true" autocomplete :filter="vFilter" append-icon="keyboard_arrow_down" @input="computedDepends(c, $event)")
                                        v-radio-group(v-if="c.type == 3" v-model="model.custom[c.uuid]")
                                            label {{ c.placeholder }}{{c.required ? '&bull;':''}}
                                            v-radio(v-for="(opt, i) in c.options" :key="'opt2'+i" :value="(typeof opt == 'string' ? opt : opt.option)" :label="(typeof opt == 'string' ? opt : opt.option)")
                                
                        v-card-actions
                            .btns.btns-right
                                v-btn(color="secondary" small @click="close") {{ $t('Cancelar') }}
                                v-btn(:loading="loadingBtn" color="primary" large @click.stop="save") {{ $t('Guardar') }}
</template>

<script>

import _m from 'lodash/merge'
import fn from 'mixins/fn'
import Vue from 'vue'

export default {
    mixins: [fn],
    data(){
        return {

            // Vista
            modal     : false,
            loadingBtn: false,
            preload   : true,

            // Mensajes
            manageMsj  : false,
            manageColor: '',
            manageText : '',

            // Datos
            modelSchema: {
                username : '',
                names    : '',
                surname  : '',
                surname2 : '',
                email    : '',
                phone    : '',
                countryid: '',
                stateid  : '',
                cityid   : '',
                gender   : '',
                birthdate: null,
                zones    : [],
                document : '',
                custom   : {},
            },
            model: {},
            uuid: '',
            networkid: '',

            modalBirthdate: false,
            state: [],
            city: [],
            country: [],
            zones: [],
            zonei: -1,
            customForm: [],
            availableRow: []
        }
    },
    computed: {
        // Existe méxico
        hasMexico(){
            return this.country.some(c => c.uuid == 'd7e944ac-67a8-52b3-a303-6c520f928d69')
        },
        phone(){
            let country = this.country.find((item) => item.uuid == this.model.countryid)
            return country ? country.tel : ''
        }
    },
    watch: {
        modalBirthdate (val) {
            val && this.$nextTick(() => (this.$refs.birthdate.activePicker = 'YEAR'))
        },
    },
    methods: {
        async open(data){

            this.modal = true
            this.preload = true

            this.uuid = data.uuid
            this.networkid = data.networkid

            this.model = this._.cloneDeep(this.modelSchema)

            await this.fetchProfile()

            await this.fetchCountry()
            await this.fetchState()
            await this.fetchCity()
            
            if (this.model.zones.length) {
                
                let zone = this.model.zones[0]
                let zones = this.model.zones.slice(1, this.model.zones.length)

                await this.fetchZone()
                this.model.zones.splice(0, 1, zone)

                if(!zones.length){

                    this.zonei = 0
                    await this.fetchZone(zone)
                }

                let zlen = zones.length
                for(let i = 1, j=0; i <= zlen; i++, j++){

                    let parent = this.model.zones.slice(-1)[0]
                    this.zonei = i

                    this.model.zones.push(zones[j])

                    let push_empty_item = zlen == i ? false : true
                    await this.fetchZone(parent, push_empty_item)
                }

            } else {
                await this.fetchZone()
            }

            this.fetchForm()
            this.preload = false
        },
        close(){
            this.model      = this._.cloneDeep(this.modelSchema)
            this.loadingBtn = false
            this.modal      = false
        },
        fetchProfile() {

            return new Promise((resolve) => {

                this.$api(this, (xhr) => {
                    xhr.get('/network/profile/user', {
                        params: {
                            networkid : this.networkid,
                            uuid      : this.uuid
                        }
                    }).then((r) => {

                        let data = r.data
                        if (data.response) {
                            this.model = this._.cloneDeep(this._.merge(this.model, data.data))
                        }
                        resolve()
                    }).catch(() => resolve())
                })
            })
        },
        fetchCountry(){

            return new Promise((resolve) => {
                this.$api(this, (xhr) => {
                    xhr.get('/country', {
                        params: {ab: 1, networkid: this.networkid }
                    }).then((r) => {

                        let data = r.data
                        if(data.response){
                            this.country = data.data
                        }
                        resolve()
                    }).catch(() => {})
                })
            })
        },
        fetchState(){

            return new Promise((resolve) => {
                this.$api(this, (xhr) => {
                    xhr.get('/state', {
                        params: {
                            countryid: this.model.countryid,
                            ab: 1, 
                            networkid: this.networkid
                        }
                    }).then((r) => {

                        let data = r.data
                        if(data.response){
                            this.state = data.data
                        }
                        resolve()
                    }).catch(() => {})
                })
            })
        },
        fetchCity(){

            return new Promise((resolve) => {
                this.$api(this, (xhr) => {
                    xhr.get('/city', {
                        params: {
                            stateid: this.model.stateid,
                            ab: 1, 
                            networkid: this.networkid
                        }
                    }).then((r) => {

                        let data = r.data
                        if(data.response){
                            this.city = data.data
                        }
                        resolve()
                    }).catch(() => {})
                })
            })
        },
        fetchZone(item, push_empty_item = true){

            return new Promise((resolve) => {

                this.zones = this.zonei == -1 ? [] : this.zones.slice(0, this.zonei + 1)
                this.model.zones = this.zonei == -1 ? [] : this.model.zones.slice(0, this.zonei+1)

                this.$api(this, (xhr) => {
                    xhr.get('/zone', {
                        params: {
                            cityid: this.model.cityid,
                            parent: this.zonei == -1 ? 0 : item,
                            ab: 1,
                            networkid: this.networkid
                        }
                    }).then((r) => {

                        let data = r.data
                        if(data.response){
                            if(data.data.length){

                                if (push_empty_item) {
                                    this.model.zones.push('')    
                                }

                                this.zones.push(data.data)
                            }
                        }
                        resolve()
                    }).catch(() => {})
                })
            })
        },
        fetchForm() {

            this.$api(this, (xhr) => {
                xhr.get('/network/form', {
                    params: {
                        networkid: this.networkid
                    }
                }).then((r) => {

                    let data = r.data
                    if (data.response) {

                        let custom = data.data.custom.map((c) => {
                            c.required   = c.required == 1 ? true : false
                            c.visibility = c.visibility == 1 ? true : false
                            return c
                        })

                        this.customForm = custom
                        this.availableRow = data.data.available == null ? [] : data.data.available

                        let list = this.customForm.filter((c) => c.type == 4)

                        for(let l of list){

                            let dat    = this.model.custom[l.uuid]
                            let option = ''

                            if(Array.isArray(l.options)){
                                option = l.options.find((o) => {
                                    if(typeof o == 'string'){
                                        return dat
                                    }
                                    return o.option == dat
                                })
                            }else{
                                let depends = this.model.custom[l.depends]
                                if(depends != 'undefined'){
                                    option = l.options[depends.uuid].find((o) => {
                                        if(typeof o == 'string'){
                                            return dat
                                        }
                                        return o.option == dat
                                    })
                                }
                            }

                            this.model.custom[l.uuid] = option
                            this.computedDepends(l, option)
                        }
                    }
                }).catch(() => {})
            })
        },
        save() {
            let post = this._.cloneDeep(this.model)
            post.zones = JSON.stringify(post.zones)

            let custom = this._.cloneDeep(post.custom)

            for(let c in custom){
                if(typeof custom[c] != 'string'){

                    if(custom[c] == undefined){
                        custom[c] = ''
                    }else{
                        custom[c] = Array.isArray(custom[c]) ? custom[c] : custom[c].option
                    }
                }
            }

            post.custom = JSON.stringify(custom)
            post.uuid = this.uuid
            post.networkid = this.networkid

            this.loadingBtn = true
            this.$api(this, (xhr) => {
                xhr.post('/network/profile/user', this.$qs.stringify(post)).then((r) => {

                    let data = r.data
                    this.loadingBtn = false
                    this.manageMsj = true
                    
                    if(!data.response){
                        this.manageColor = 'error'
                        this.manageText = data.message
                    }else{
                        this.manageColor = 'success'
                        this.manageText = data.message
                        this.$emit('success')
                        this.close()
                    }
                }).catch(() => {
                    this.loadingBtn = false
                })
            })
        },
        computedDepends(el, parent){

            let depends = this.customForm.filter((c) => {
                return c.hasOwnProperty('depends') && c.depends == el.uuid
            })

            for(let depend of depends){

                let index = this.customForm.findIndex((c) => c.uuid == depend.uuid)
                if(parent != null && depend.options.hasOwnProperty(parent.uuid)){
                    Vue.set(this.customForm[index], 'coptions', depend.options[parent.uuid])
                }else{
                    Vue.set(this.customForm[index], 'coptions', [])
                }
            }
        }
    }
}

</script>
