<template lang="pug">
    div
        loader(v-if="$root.loader")
        template(v-else)
            header-component
                v-btn.no-margin(absolute dark fab top right color='primary' @click.stop="drawerRight = !drawerRight")
                    v-icon search
            v-navigation-drawer.filters.grey.darken-3(fixed v-model="drawerRight" right app width="250")
                v-form
                    v-toolbar.grey.darken-1(flat)
                        v-text-field(v-model="filter.query" :placeholder="$t('Buscar por nombre, correo, teléfono...')" dark)
                        v-icon(dark) search
                    .filters-content
                        .white--text.mb-2 {{$t('Filtrar por')}}:  
                        v-select.mb-3(
                            v-if="!isemail"
                            v-model="filter.network"
                            :items='networkNames'
                            item-text="name"
                            item-value="uuid"
                            :label='$t("Red")'
                            append-icon="keyboard_arrow_down" 
                            autocomplete
                            :filter="vFilter"
                            dark
                            color="white"
                        )
                        v-select.mb-3(
                            v-model="filter.country"
                            :items='country'
                            :label='$t("País")' 
                            item-text="name"
                            item-value="uuid"
                            append-icon="keyboard_arrow_down"
                            autocomplete
                            :filter="vFilter"
                            dark
                            color="white"
                            @input="fetchState(),fetchRegion()"
                        )
                        v-select.mb-3(
                            v-model="filter.state"
                            :items='state'
                            :label='$t("Estado/Departamento/Provincia")' 
                            item-text="name"
                            item-value="uuid"
                            append-icon="keyboard_arrow_down"
                            autocomplete
                            :filter="vFilter"
                            dark
                            color="white"
                            @input="fetchCity"
                        )
                        v-select.mb-3(
                            v-model="filter.city"
                            :items='city' 
                            :label='$t("Ciudad/Municipio")' 
                            item-text="name"
                            item-value="uuid"
                            append-icon="keyboard_arrow_down" 
                            autocomplete
                            :filter="vFilter"
                            dark
                            color="white"
                        )
                        v-select.mb-3(
                            v-if="filter.country"
                            v-model="filter.region"
                            :items='region'
                            :label='$t("Región especial")' 
                            item-text="name"
                            item-value="uuid"
                            append-icon="keyboard_arrow_down"
                            autocomplete
                            :filter="vFilter"
                            dark
                            color="white"
                        )
                        v-dialog.mb-3(ref='dialogStart' v-model='modalDateStart' lazy full-width width='290px' :return-value.sync='filter.from')
                            v-text-field(slot='activator' :label='$t("Desde")' v-model='filter.from' readonly dark color="white")
                            v-date-picker(:locale="$root.currentLanguage" v-model='filter.from' scrollable)
                                v-spacer
                                v-btn(flat color='primary' @click.stop='modalDateStart = false') {{$t('Cancelar')}}
                                v-btn(flat color='primary' @click.stop='$refs.dialogStart.save(filter.from)') {{$t('OK')}}
                        v-dialog.mb-4(ref='dialogEnd' v-model='modalDateEnd' lazy full-width width='290px' :return-value.sync='filter.to')
                            v-text-field(slot='activator' :label='$t("Hasta")' v-model='filter.to' readonly dark color="white")
                            v-date-picker(:locale="$root.currentLanguage" v-model='filter.to' scrollable)
                                v-spacer
                                v-btn(flat color='primary' @click.stop='modalDateEnd = false') {{$t('Cancelar')}}
                                v-btn(flat color='primary' @click.stop='$refs.dialogEnd.save(filter.to)') {{$t('OK')}}
                        .white--text.mb-2 {{$t('Ordenar/filtrar por')}}:
                        v-select.mb-3(
                            v-model="filter.notes_author"
                            :items='filterNotesAuthor'
                            item-text="text"
                            item-value="key"
                            :label='$t("Conversaciones mías")'
                            append-icon="keyboard_arrow_down" 
                            autocomplete
                            :filter="vFilter"
                            dark
                            color="white"
                        )
                        v-select.mb-3(
                            v-model="filter.notes_any"
                            :items='filterNotesAuthor'
                            item-text="text"
                            item-value="key"
                            :label='$t("Conversaciones")'
                            append-icon="keyboard_arrow_down" 
                            autocomplete
                            :filter="vFilter"
                            dark
                            color="white"
                        )
                        v-select.mb-3(
                            v-model="filter.notes_date"
                            :items='filterNotes'
                            item-text="text"
                            item-value="key"
                            :label='$t("Ultimas conversaciones")'
                            append-icon="keyboard_arrow_down" 
                            autocomplete
                            :filter="vFilter"
                            dark
                            color="white"
                        )
                        v-select.mb-3(
                            v-model="filter.notes_pending"
                            :items='filterNotesAuthor'
                            item-text="text"
                            item-value="key"
                            :label='$t("Pendientes")'
                            append-icon="keyboard_arrow_down" 
                            autocomplete
                            :filter="vFilter"
                            dark
                            color="white"
                        )
                        .white--text.mt-5 {{$t('Filtrar por nivel')}}:
                        v-select.mb-3(
                            v-model="filter.level"
                            :items='levels'
                            :label='$t("Nivel")'
                            append-icon="keyboard_arrow_down" 
                            autocomplete
                            dark
                            color="white"
                        )
                        .btns.btns-right
                            v-btn(@click="cleanFilter" color="primary") {{ $t('Limpiar') }}
            v-content
                v-snackbar(
                :timeout="3000"
                :top="true"
                :right="true"
                :color="accountColor"
                v-model="accountMsj")
                    | {{ accountText }}
                    v-btn(flat dark @click.native="accountMsj = false") {{ $t('cerrar') }}
                v-snackbar(
                :timeout="3000",
                :top="true"
                :right="true"
                :color="goalColor"
                v-model="goalMsj")
                    | {{ goalText }}
                    v-btn(flat, dark, @click.native="goalMsj = false") {{ $t('cerrar') }}
                v-container.grid-list-xl(fluid)
                    h1 {{ $t('Posiciones') }}
                    template(v-if="preload")
                        h3.mb-5 {{$t('Cargando')}}...
                    v-data-table(v-else
                        ref="table"
                        :headers='headers'
                        :items='network'
                        :pagination.sync='pagination'
                        :rows-per-page-items="$root.rowsPageItems"
                        :rows-per-page-text="$root.rowsPageText" 
                        item-key='names'
                        :total-items="total"
                        :loading="loading"
                    )   
                        template(slot="no-results")
                            | {{$t('No se han encontrado resultados')}}
                        template(slot="no-data")
                            | {{$t('No se han encontrado resultados')}}
                        template(slot='headers' slot-scope='props')
                            tr
                                th(v-for='header in props.headers' :key='header.text' :class="['column sortable', pagination.descending ? 'desc' : 'asc', header.value === pagination.sortBy ? 'active' : '']" @click='changeSort(header.value)' :align="header.align")
                                    span.subheading {{ $t(header.text) }}
                                    v-icon(small) arrow_upward
                        template(slot='items' slot-scope='props')
                            tr
                                td(align="center")
                                    img.medal-table(v-if="(props.item.rank) == 1" src="/public/img/icons/medalla-1.png")
                                    img.medal-table(v-if="(props.item.rank) == 2" src="/public/img/icons/medalla-2.png")
                                    img.medal-table(v-if="(props.item.rank) == 3" src="/public/img/icons/medalla-3.png")
                                    template(v-if="(props.item.rank) > 3") {{ (props.item.rank) }}
                                td
                                    v-layout(align-center)
                                        v-flex.text-xs-center
                                            v-avatar.grey.lighten-4(size='27')
                                                img(v-if="photoUrl(props.item.photo).length" :src="photoUrl(props.item.photo)")
                                                v-gravatar(v-else :email="props.item.email")
                                            div.badges.my-2
                                                v-icon(v-for="(badge, i) in props.item.badges" small :color="badge.color" :key="'bdr'+i") verified
                                        v-flex(xs12 d-flex align-center)
                                            | {{ props.item.names }}
                                td.text-xs-right
                                    v-tooltip(top)
                                        template(slot="activator")
                                            v-icon.cur-p.primary--text(@click="$refs.notes.open(props.item)") question_answer
                                        span {{ $t('Conversación') }}
                                    v-tooltip(top)
                                        template(slot="activator")
                                            v-btn.blue.white--text.mr-0(style="font-size:70%;width:24px;height:24px;border-radius:100px;" fab small depressed) {{ props.item.notes.total_mine }}
                                        span {{ $t('Mis respuestas') }}
                                    v-tooltip(top)
                                        template(slot="activator")
                                            v-btn.red.white--text.ml-1(style="font-size:70%;width:24px;height:24px;border-radius:100px;" fab small depressed) {{ props.item.notes.total_other }}
                                        span {{ $t('Respuestas de otros') }}
                                    v-divider.grey.x1.mb-1
                                    v-menu
                                        a.primary--text(slot='activator') {{ $t('Ver más') }}
                                        v-list.sheet-menu
                                            v-list-tile.white--text(v-if="$root.profile.type == 0 || $root.profile.type == 2" @click="canAccountOpen(props.item)")
                                                v-list-tile-title {{ $t('Permisos de usuario') }}
                                            v-list-tile.white--text(@click="openGoal(props.item)")
                                                v-list-tile-title {{ $t('Retos') }}
                                            v-list-tile.white--text(@click="$refs.editProfile.open(props.item)")
                                                v-list-tile-title {{ $t('Editar usuario') }}
                                            v-list-tile.white--text(v-if="$root.profile.type == 0 || $root.profile.type == 2" @click="deleteAccountOpen(props.item)")
                                                v-list-tile-title {{ $t('Eliminar usuario') }}
                                            v-list-tile.white--text(@click="formData(props.item)")
                                                v-list-tile-title {{ $t('Datos adicionales') }}
                                            v-list-tile.white--text(@click="$refs.reset.open(props.item.uuid)")
                                                v-list-tile-title {{ $t('Cambiar contraseña') }}
                                            v-list-tile.white--text(@click="$refs.badge.open(props.item.uuid, props.item.badges)")
                                                v-list-tile-title {{ $t('Asignar insignia') }}
                                            v-list-tile.white--text(@click="$refs.referral.open(props.item.uuid)")
                                                v-list-tile-title {{ $t('Asignar referidor') }}
                                            v-list-tile.white--text(v-if="$root.profile.type == 0 || $root.profile.type == 2" @click="$refs.highlightNetwork.open(props.item)")
                                                v-list-tile-title {{ $t('Destacar redes') }}
                                            v-list-tile.white--text(v-if="$root.profile.type == 0 || $root.profile.type == 2" @click="$refs.sendWhatsapp.open(props.item)")
                                                v-list-tile-title {{ $t('Enviar WhatsApp') }}
                                td
                                    template
                                        v-progress-circular.text-xs-center(:size="24" v-if="props.item.loadChart" indeterminate color="primary")
                                        v-icon.cur-p.primary--text(v-else @click="fetchDetailChart(props.item.uuid, props.item)") pie_chart
                                    template
                                        v-progress-circular.text-xs-center(:size="24" v-if="props.item.loadRank" indeterminate color="primary")
                                        v-tooltip(top v-else)
                                            template(slot="activator")
                                                v-icon.cur-p.primary--text(@click="fetchDetailRank(props.item.uuid, props.item)") star
                                            span {{ $t('Rankings') }}
                                    template(v-if="props.item.isgoal || props.item.uisgoal")
                                        v-progress-circular.text-xs-center(:size="24" v-if="props.item.loadGoal" indeterminate color="primary")
                                        v-tooltip(v-else top)
                                            template(slot="activator")
                                                v-icon.cur-p.primary--text(@click="fetchDetailGoal(props.item.uuid, props.item)") stars
                                            span {{ $t('Retos') }}
                                    template(v-if="props.item.istotal")
                                        v-tooltip(top)
                                            template(slot="activator")
                                                v-icon.cur-p.primary--text(@click="$refs.total.open(props.item)") show_chart
                                            span {{ $t('Total de usuarios invitados') }}
                                    span.mt-2.d-block {{ $t('Total') }}: {{ props.item.total }}
                                td {{ props.item.totalshare }}
                                td {{ props.item.code }}
                                td 
                                    template(v-if="props.item.referid != null")
                                        v-progress-circular.text-xs-center(:size="24" v-if="props.item.loadChartRefer" indeterminate color="primary")
                                        v-icon.cur-p.primary--text(v-else @click="fetchDetailChart(props.item.referid, props.item, true)") pie_chart
                                    span.mt-2.d-block {{ props.item.refer }}
                                td
                                    a(target="blank" @click.stop="$refs.sendWhatsappUrl.open(props.item)") {{ $t('Enviar mensaje promoción de red') }}
                                td
                                    | {{ $t('Fecha')}}: {{ props.item.datecreated | moment('YYYY-MM-DD') }} 
                                    br
                                    | {{$t('Hora')}}: {{ props.item.datecreated | moment('HH:mm') }}
                                td {{ props.item.phone }}
                                td {{ props.item.city }}
                                td(style="min-width:220px")
                                    template(v-for="zone in props.item.zones")
                                        small {{zone.division}}: {{zone.name}}
                                        br
                                td {{ props.item.age }}
                                td
                                    a.email-table {{ props.item.email }}
                                td {{ props.item.document }}
                                td {{ props.item.network }}

                                // Valores redes rodolfistas
                                template(v-if="filter.network && isRodolfista")
                                    template(v-for="(field, k) in props.item.field")
                                        td(v-if="isAvailable(k)") {{ field }}
                                template(v-if="filter.network && Object.keys(props.item.custom).length")
                                    td(v-for="(c, i) in props.item.custom" :key="'c'+i")
                                        | {{ c }}
                        template(slot='pageText' slot-scope='props')
                            | Filas {{ props.pageStart }} - {{ props.pageStop }} de {{ props.itemsLength }}
                    //- Opciones
                    .btns.btns-fixed.btns-bottom.btns-left(v-if="!isemail || (isemail && isEmail)")
                        v-tooltip(top v-if="profile.type == 0 || !isRodolfista")
                            template(slot="activator")
                                v-btn(:loading="loadingBtn" @click="download" dark fab color='primary')
                                    v-icon file_download
                            span {{ $t('Descargar reporte') }}
                        v-tooltip(top v-if="profile.type == 0 || !isRodolfista")
                            template(slot="activator")
                                v-btn(:loading="loadingBtnLevel" @click="downloadLevel" dark fab color='primary')
                                    v-icon account_tree
                            span {{ $t('Descargar reporte de niveles') }}
                        v-tooltip(top v-if="filter.network && (profile.type == 0 || !isRodolfista)")
                            template(slot="activator")
                                v-btn(:loading="loadingBtnCrm" @click="downloadCrm" dark fab color='primary')
                                    v-icon dataset
                            span {{ $t('Descargar datos de los usuarios') }}
                        v-tooltip(top v-if="profile.type == 0")
                            template(slot="activator")
                                v-btn(@click="$refs.importUser.open()" dark fab color='primary')
                                    v-icon file_upload
                            span {{ $t('Importar usuarios') }}
                        v-btn(v-if="multipleItems.length" :loading="loadingBtn" dark fab color='primary')
                            v-icon more_vert
                        v-btn(v-if="multipleItems.length" @click="selectAll('network', 'uuid')" dark fab color='primary')
                            v-icon check_box
                        v-btn(v-if="multipleItems.length" @click="multipleItems=[]" dark fab color='primary')
                            v-icon check_box_outline_blank
            chart(ref="chart" :referral="false")
            rank(ref="rank")
            goal(ref="goal")
            total(ref="total")
            notes(ref="notes")
            form-data(ref="form")
            can-account(ref="canAccount")
            reset-cmp(ref="reset")
            badge(ref="badge" @success="reFetchNetwork")
            assign-referral(ref="referral" @success="reFetchNetwork")
            highlight-network-by-user(ref="highlightNetwork" @success="reFetchNetwork")
            edit-profile(ref="editProfile" @success="reFetchNetwork")
            import-user(ref="importUser" @success="reFetchNetwork")

            // Enviar mensaje a WhatsApp
            send-whatsapp-url(ref='sendWhatsappUrl')

            // Enviar chat a WhatsApp
            send-whatsapp(ref="sendWhatsapp")

            v-dialog(v-model='deleteAccount' max-width='700px')
                v-form
                    v-card
                        v-card-title.pb-0.justify-center {{$t('Eliminar la cuenta de')}} {{ account.names }}
                        v-card-text.text-xs-center
                            h3 {{$t('¿Estas seguro de eliminar esta cuenta?, se perderá toda la información de la cuenta y no podrás recuperarla')}}
                            v-divider.my-2
                            v-checkbox(:value="1" v-model="reason" :label="$t('Estoy recibiendo mucha publicidad')")
                            v-checkbox(:value="2" v-model="reason" :label="$t('Alguien más se registró por mi')")
                            v-checkbox(:value="3" v-model="reason" :label="$t('Ya no sigo interesado en hacer parte de la red')")
                        v-card-actions.pt-0
                            .btns.btns-center
                                v-btn(small color='primary' @click.stop='deleteAccount=false') {{ $t('Cancelar') }}
                                v-btn(large color='accent' :loading="loadingAccount" @click="deleteAccountM") {{ $t('Eliminar') }}
            v-dialog(v-model="goalModal" max-width="700")
                v-form
                    v-card
                        v-card-title {{ $t('Retos del usuario de la red') }}
                        v-card-text
                            v-layout(row wrap)
                                v-flex(xs12)
                                    label {{ $t('Habilitado') }}
                                    v-divider.mt-2
                                    .switch-group
                                        label {{$t('No')}}
                                        v-switch(:label="$t('Si')" color="success" v-model="isGoalUser")
                                    br
                                template(v-if="isGoalUser")
                                    v-flex(xs12 sm6)
                                        v-text-field(:label="$t('Retos de referidos por mes')" type="number" v-model="goalModel.month")
                                    v-flex(xs12 sm6)
                                        v-text-field(:label="$t('Retos de referidos por semana')" type="number" v-model="goalModel.week")
                                    v-flex(xs12 sm6)
                                        v-text-field(:label="$t('Retos de referidos por día')" type="number" v-model="goalModel.day")
                                    v-flex(xs12 sm6)
                                        v-text-field(:label="$t('Retos de referidos directos por mes')" type="number" v-model="goalModel.smonth")
                                    v-flex(xs12 sm6)
                                        v-text-field(:label="$t('Retos de referidos directos por semana')" type="number" v-model="goalModel.sweek")
                                    v-flex(xs12 sm6)
                                        v-text-field(:label="$t('Retos de referidos directos por día')" type="number" v-model="goalModel.sday")
                        v-card-actions
                            .btns.btns-right
                                v-btn(color="secondary" small @click.native="goalModal = false") {{ $t('Cancelar') }}
                                v-btn(:loading="loadingGoal" color="primary" large @click.native="saveGoal") {{ $t('Guardar') }}
</template>

<script>

    import auth from 'mixins/auth'
    import config from 'src/config'
    import _ from 'lodash/debounce'
    import fn from 'mixins/fn'
    import AssignReferral from 'components/AssignReferral'
    import HighlightNetworkByUser from 'components/network/HighlightNetworkByUser'
    import SendWhatsApp from 'components/whatsapp/SendWhatsApp'
    import SendWhatsAppUrl from 'components/whatsapp/SendWhatsAppUrl'
    import EditProfile from 'components/profile/Edit'
    import ImportUser from 'components/user/ImportUser'

    export default {
        mixins: [auth, fn],
        metaInfo() {
            return {
                title: this.$t('Posiciones')
            }
        },
        data() {
            return {
                availableRow: [],
                filterNotes: [
                    {
                        key: 'notes_desc',
                        text: 'Ultimas conversaciones'
                    },
                    {
                        key: 'notes_asc',
                        text: 'Antiguas conversaciones'
                    }
                ],
                filterNotesAuthor: [
                    {
                        key: 1,
                        text: 'SI'
                    },
                    {
                        key: 0,
                        text: 'NO'
                    }
                ],
                loadingGoal: false,
                goalModal: false,
                goalUser: '',
                isGoalUser: false,
                goal: {
                    month: 0,
                    week : 0,
                    day  : 0,
                    smonth: 0,
                    sweek: 0,
                    sday: 0
                },
                goalModel: {},
                goalMsj  : false,
                goalColor: '',
                goalText : '',
                total: 0,
                loading: false,
                pagination: {
                    page: 1,
                    rowsPerPage: 20
                },
                drawerRight: false,
                modalDateStart: false,
                modalDateEnd: false,
                filter: {},
                filterSchema: {
                    query        : '',
                    network      : '',
                    country      : '',
                    state        : '',
                    city         : '',
                    region       : '',
                    from         : null,
                    to           : null,
                    notes_date   : '',
                    notes_author : '',
                    notes_any    : '',
                    notes_pending: '',
                    level        : ''
                },
                headersSchema: [
                    {
                        text: 'No.',
                        align: 'center',
                        value: 'rank'
                    },
                    {
                        text: 'Nombre',
                        align: 'left',
                        value: 'names'
                    },
                    {
                        text: 'Opciones',
                        align: 'right',
                        value: 'options',
                        sortable: false
                    },
                    {
                        text: 'Total de red',
                        align: 'left',
                        value: 'total'
                    },
                    {
                        text: 'Total de referidos',
                        align: 'left',
                        value: 'totalshare'
                    },
                    {
                        text: 'Código de referido',
                        align: 'left',
                        value: 'code'
                    },
                    {
                        text: 'Referidor',
                        align: 'left',
                        value: 'refer'
                    },
                    {
                        text: 'Mensaje promoción de red',
                        align: 'left',
                        value: 'whatsapp',
                        sortable: false
                    },
                    {
                        text: 'Fecha de registro',
                        align: 'left',
                        value: 'datecreated'
                    },
                    {
                        text: 'Celular',
                        align: 'left',
                        value: 'phone'
                    },
                    {
                        text: 'Ciudad/Municipio',
                        align: 'left',
                        value: 'city'
                    },
                    {
                        text: 'Zonas',
                        align: 'left',
                        value: 'zone',
                        sortable: false
                    },
                    {
                        text: 'Edad',
                        align: 'left',
                        value: 'age'
                    },
                    {
                        text: 'Correo electrónico',
                        align: 'left',
                        value: 'email'
                    },
                    {
                        text: 'Número de documento de identificación',
                        align: 'left',
                        value: 'document'
                    },
                    {
                        text: 'Red',
                        align: 'left',
                        value: 'network'
                    }
                ],
                headers: [],
                network: [],
                networkNames: [],
                country: [],
                state: [],
                city: [],
                region: [],
                loadingBtn: false,
                loadingBtnLevel: false,
                loadingBtnCrm: false,
                cancelFilter: null,
                preload: false,
                deleteAccount: false,
                loadingAccount: false,
                reason: 3,
                account: {},
                accountMsj   : false,
                accountColor : '',
                accountText  :  '',
                multipleItems: []
            }
        },
        computed: {
            profile(){
                return this.$root.profile
            },
            levels(){
                return Array.from({length: 1000}, (v, i) => i+1)
            },
            isRodolfista(){
                return this.isemail ? this.profile.isRodolfista : (this.filter.network && (this.networkNames.find(n => n.uuid == this.filter.network && n.isRodolfista) ? true : false))
            },
            isEmail() {
                return this.$root.profile.network != undefined && 'email' in this.$root.profile.network && this.$root.profile.network.email.indexOf(this.$root.profile.email) != -1
            },
            isComment() {
                return this.$root.profile.network != undefined && 'email_comment' in this.$root.profile.network && this.$root.profile.network.email_comment.indexOf(this.$root.profile.email) != -1
            }
        },
        watch: {
            filter: {
                handler(){

                    this.pagination.page = 1
                    this.preload = true
                    this.total = 0
                    this.network = []
                    this.fetchNetwork()

                    this.headers = []
                    this.$nextTick(() => {
                        
                        this.headers = this._.cloneDeep(this.headersSchema)
                        if(this.filter.network){
                            this.fetchForm()
                        }
                    })
                },
                deep: true
            },
            profile: {
                handler(){
                    this.networkNames = []
                    this.fetchNetworkNames()
                },
                deep: true
            },
            pagination: {
                handler () {
                    this.fetchNetwork()
                },
                deep: true
            }
        },
        created() {

            this.fetchNetworkNames()
            this.fetchCountries()
            this.filter = Object.assign({}, this.filterSchema)
        },
        methods: {

            reFetchNetwork(){
                this.fetchNetwork()
            },

            formData(item){
                this.$refs.form.open(item)
            },

            fetchForm() {
                this.$api(this, (xhr) => {
                    xhr.get('/network/form', {
                        params: {
                            networkid: this.filter.network
                        }
                    }).then((r) => {

                        let data = r.data
                        if (data.response) {
                            
                            let customForm    = data.data.custom
                            this.availableRow = data.data.available == null ? [] : data.data.available

                            this.setFields()

                            for(let c of customForm){

                                if(this.headers.some((h) => h.value == c.uuid)){
                                    continue
                                }

                                this.headers.push({
                                    text  : c.placeholder,
                                    align : 'left',
                                    value : c.uuid,
                                    sortable: false
                                })
                            }
                        }
                    }).catch(() => {})
                })
            },

            /**
             * Obtener cabeceras y campos por filtro de red rodolfista
             */
            setFields(){

                if(!this.isRodolfista){
                    return
                }

                const fieldHeaders = [
                    {
                        text  : this.$t('Departamento donde estudió'),
                        value : 'field_state'
                    },
                    {
                        text  : this.$t('Ciudad donde estudió'),
                        value : 'field_city'
                    },
                    {
                        text  : this.$t('Colegio donde estudió'),
                        value : 'field_school'
                    },
                    {
                        text  : this.$t('Universidad donde estudió'),
                        value : 'field_institution'
                    },
                    {
                        text  : this.$t('Grados recibidos o por recibir de pregado o postgrado'),
                        value : 'field_grade'
                    },
                    {
                        text  : this.$t('Área de estudio'),
                        value : 'field_area'
                    },
                    {
                        text  : this.$t('Otra área de estudio'),
                        value : 'field_other_area'
                    }
                ]

                for(let f of fieldHeaders){

                    if(this.headers.some((h) => h.value == f.value)){
                        continue
                    }

                    let isEnabled = this.availableRow.some(t => t.tag == f.value && t.enable == 1)
                    if(!isEnabled){
                        return
                    }

                    this.headers.push({
                        text    : f.text,
                        align   : 'left',
                        value   : f.value,
                        sortable: false
                    })
                }
            },

            photoUrl(photo){

                if(photo != null && photo.length){
                    return config.uploaduser + photo
                }
                return ''
            },

            deleteAccountOpen(item){

                this.account = this._.cloneDeep(item)
                this.deleteAccount = true
                this.reason = null
            },

            /**
             * Abrir opciones de permisos
             */
            canAccountOpen(item){
                this.$refs.canAccount.open(item)
            },

            deleteAccountM(){

                this.loadingAccount = true
                this.$api(this, (xhr) => {
                    xhr.delete('/user?reason=' + this.reason+'&uuid='+this.account.uuid).then((r) => {
                        
                        this.loadingAccount = false
                        this.accountMsj = true

                        let data = r.data

                        if(data.response){
                            this.accountColor = 'success'
                            this.accountText = data.message
                            this.fetchNetwork()
                            this.deleteAccount = false
                        }else{
                            this.accountColor = 'error'
                            this.accountText = data.message
                        }
                    }).catch(() => {})
                })
            },

            openGoal(item){

                this.isGoalUser = false
                this.goalUser   = item.uuid
                this.goalModal  = true
                this.isGoalUser = item.uisgoal ? true : false
                this.goalModel  = this._.cloneDeep(Object.assign(this.goalModel, item.goal_user))
            },

            fetchDetailChart(uuid, item={}, refer=false){

                if(Object.keys(item).length){
                    item[refer ? 'loadChartRefer' : 'loadChart'] = true
                }else{
                    this.loadChart = true
                }

                this.$api(this, (xhr) => {
                    xhr.get('/report/network/graph/detail?uuid='+uuid).then((r) => {

                        if(Object.keys(item).length){
                            item[refer ? 'loadChartRefer' : 'loadChart'] = false
                        }else{
                            this.loadChart = false
                        }

                        let data = r.data
                        let itemData = this._.cloneDeep(data.data)
                        this.$refs.chart.open(itemData)

                    }).catch(() => {
                        if(Object.keys(item).length){
                            item[refer ? 'loadChartRefer' : 'loadChart'] = false
                        }else{
                            this.loadChart = false
                        }
                    })
                })
            },

            fetchDetailRank(uuid, item={}){

                if(Object.keys(item).length){
                    item.loadRank = true
                }else{
                    this.loadRank = true
                }

                this.$api(this, (xhr) => {
                    xhr.get('/report/network/graph/detail-rank', {
                        params: {
                            uuid,
                            key: 'ever'
                        }
                    }).then((r) => {

                        let merge = {}
                        if(Object.keys(item).length){
                            item.loadRank = false
                            merge = item
                        }else{
                            this.loadRank = false
                            merge = this.$root.profile
                        }
                        
                        let data = r.data
                        let itemData = this._.cloneDeep(data.data)
                        this.$refs.rank.open(Object.assign(this._.cloneDeep(merge), itemData))

                    }).catch(() => {
                        if(Object.keys(item).length){
                            item.loadRank = false
                        }else{
                            this.loadRank = false
                        }
                    })
                })
            },

            fetchDetailGoal(uuid, item={}){

                if(Object.keys(item).length){
                    item.loadGoal = true
                }else{
                    this.loadGoal = true
                }

                this.$api(this, (xhr) => {
                    xhr.get('/report/network/graph/detail-goal?uuid='+uuid).then((r) => {

                        if(Object.keys(item).length){
                            item.loadGoal = false
                        }else{
                            this.loadGoal = false
                        }

                        let data = r.data
                        let itemData = this._.cloneDeep(data.data)
                        this.$refs.goal.open(itemData)

                    }).catch(() => {
                        if(Object.keys(item).length){
                            item.loadGoal = false
                        }else{
                            this.loadGoal = false
                        }
                    })
                })
            },

            fetchNetworkNames(){

                let url = '/network?list=1'

                if(this.$root.profile.type == 2){
                    url = '/network/profile?list=1'
                }

                this.$api(this, (xhr) => {
                    xhr.get(url).then((r) => {

                        let data = r.data
                        if(data.response){
                            this.networkNames = [[]].concat(data.data)
                        }
                    }).catch(() => {})
                })
            },

            fetchCountries(){

                this.$api(this, (xhr) => {
                    xhr.get('/country').then((r) => {

                        let data = r.data
                        if(data.response){
                            this.country = data.data
                        }
                    }).catch(() => {})
                })
            },

            fetchState(){

                this.$api(this, (xhr) => {
                    xhr.get('/state', {
                        params: {
                            countryid: this.filter.country 
                        }
                    }).then((r) => {

                        let data = r.data
                        if(data.response){
                            this.state = data.data
                        }
                    }).catch(() => {})
                })
            },

            fetchCity(){

                this.$api(this, (xhr) => {
                    xhr.get('/city', {
                        params: {
                            stateid: this.filter.state 
                        }
                    }).then((r) => {

                        let data = r.data
                        if(data.response){
                            this.city = data.data
                        }
                    }).catch(() => {})
                })
            },

            /**
             * Obtener listado de regiones especiales
             */
            fetchRegion(){

                this.$api(this, (xhr) => {
                    xhr.get('/region/list', {
                        params: {
                            countryid: this.filter.country 
                        }
                    }).then((r) => {

                        let data = r.data
                        if(data.response){
                            this.region = data.data
                        }
                    }).catch(() => {})
                })
            },

            fetchNetwork: _(function(){

                if(this.cancelFilter != null){
                    this.cancelFilter.cancel()
                }

                let CancelToken   = this.$axios.CancelToken
                this.cancelFilter = CancelToken.source()
                this.loading = true

                this.$api(this, (xhr) => {
                    xhr.get('/report/network', {
                        cancelToken: this.cancelFilter.token,
                        params: {
                            filter : JSON.stringify(this.filter),
                            limit  : this.pagination.rowsPerPage,
                            offset : (this.pagination.page-1)*this.pagination.rowsPerPage,
                            sort   : this.pagination.sortBy,
                            order  : (this.pagination.descending ? 'DESC' : 'ASC'),
                        }
                    }).then((r) => {

                        let data = r.data
                        this.preload = false
                        this.loading = false

                        if(data.response){
                            let network  = data.data.result.map((data) => {
                                data.loadChart = false
                                data.loadRank  = false
                                data.loadGoal  = false
                                data.loadChartRefer = false
                                return data
                            })
                            this.total = data.data.total
                            this.network = network
                        }
                    }).catch(() => {})
                })
            }, 500),

            changeSort (column) {

                if(column == 'multiple'){
                    return
                }
              
                if (this.pagination.sortBy === column) {
                    this.pagination.descending = !this.pagination.descending
                } else {
                    this.pagination.sortBy = column
                    this.pagination.descending = false
                }
            },

            cleanFilter(){
                this.filter = Object.assign({}, this.filterSchema)
            },

            download(){

                this.loadingBtn =  true
                let userid = this.$root.profile.uuid

                let filter = this.$qs.stringify({
                    filter : JSON.stringify(this.filter)
                })

                this.$api(this, (xhr) => {
                    xhr.post('/report/network/xls', filter).then((r) => {

                        let data = r.data
                        this.loadingBtn = false

                        if(data.response){
                            let url = config.apiurl + 'download?id=' + userid + '&file=' + data.data
                            window.location.href = url
                        }
                    }).catch(() => {})
                })
            },

            downloadLevel(){

                this.loadingBtnLevel =  true
                let userid = this.$root.profile.uuid

                let filter = this.$qs.stringify({
                    filter : JSON.stringify(this.filter)
                })

                this.$api(this, (xhr) => {
                    xhr.post('/level/export', filter).then((r) => {

                        let data = r.data
                        this.loadingBtnLevel = false

                        if(data.response){
                            let url = config.apiurl + 'download?id=' + userid + '&file=' + data.data
                            window.location.href = url
                        }
                        
                    }).catch(() => {})
                })
            },

            downloadCrm(){

                this.loadingBtnCrm =  true
                let userid = this.$root.profile.uuid

                let filter = this.$qs.stringify({
                    filter : JSON.stringify(this.filter)
                })

                this.$api(this, (xhr) => {
                    xhr.post('/crm/export', filter).then((r) => {

                        let data = r.data
                        this.loadingBtnCrm = false

                        if(data.response){
                            let url = config.apiurl + 'download?id=' + userid + '&file=' + data.data
                            window.location.href = url
                        }
                        
                    }).catch(() => {})
                })
            },

            saveGoal(){

                let post = {
                    userid: this.goalUser,
                    isgoal: this.isGoalUser ? 1 : 0,
                    goal: JSON.stringify([
                        {type:3,goal:this.goalModel.month},
                        {type:4,goal:this.goalModel.week},
                        {type:5,goal:this.goalModel.day},
                        {type:7,goal:this.goalModel.smonth},
                        {type:8,goal:this.goalModel.sweek},
                        {type:9,goal:this.goalModel.sday},
                    ])
                }

                this.loadingGoal = true
                this.$api(this, (xhr) => {
                    xhr.post('/admin/goal', this.$qs.stringify(post)).then((r) => {

                        let data = r.data
                        this.loadingGoal = false
                        this.goalMsj = true

                        if(data.response){
                            this.goalColor = 'success'
                            this.goalText = data.message
                            this.goalModal = false
                            this.preload = true
                            this.network = []
                            this.fetchNetwork()
                            this.isGoalUser = false
                        }else{
                            this.goalColor = 'error'
                            this.goalText = data.message
                        }
                    }).catch(() => {})
                })
            }
        },
        components: {
            AssignReferral,
            HighlightNetworkByUser,
            'send-whatsapp': SendWhatsApp,
            'send-whatsapp-url': SendWhatsAppUrl,
            EditProfile,
            ImportUser
        }
    }
    
</script>