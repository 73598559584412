<template lang="pug">
    div
        v-snackbar(
        :timeout="3000"
        :top="true"
        :right="true"
        :color="saveColor"
        v-model="saveMsj")
            | {{ saveText }}
            v-btn(flat dark @click.native="saveMsj = false") {{ $t('cerrar') }}
        v-dialog(lazy max-width="900px" v-model="modal" persistent)
            v-card
                v-card-title 
                    .btns.btns-right
                        v-btn(icon color="secondary" small @click.native="modal=false")
                            v-icon close
                    h3.full-width {{ $t('Enviar mensaje a WhatsApp') }}
                v-card-text
                    v-form
                        v-layout(row wrap)
                            v-flex(xs12 sm6)
                                h4.my-2 {{$t('Mensaje de WhatsApp')}}
                                v-text-field(multi-line required v-model="model.message")
                            v-flex(xs12 sm6)
                                h4.my-2 {{$t('Vista previa')}}
                                whatsapp-preview(:text="model.message")
                v-card-actions
                    .btns.btns-right
                        v-btn(color="secondary" small @click="modal = false") {{ $t('Cancelar') }}
                        v-btn(v-if="!preload" color="primary" :loading="loadingBtn" :disabled="loadingBtn" small @click="save") {{$t('Enviar')}}
</template>

<script>
export default {
    data() {
        return {
            modal     : false,
            saveMsj   : false,
            saveColor : '',
            saveText  : '',
            preload   : true,
            current: {},
            modelSchema: {
                message: ''
            },
            model: {},
            loadingBtn: false
        }
    },
    methods: {
        async open(item) {
            this.current = this._.cloneDeep(item)
            this.model   = this._.cloneDeep(this.modelSchema)
            this.modal   = true
            this.preload = false
        },
        save() {

            let post = this._.cloneDeep(this.model)
            post.userid = this.current.uuid
            post.networkid = this.current.networkid
            
            this.loadingBtn = true

            this.$api(this, (xhr) => {
                xhr.post('/v2/whatsapp/conversation/init', this.$qs.stringify(post)).then((r) => {

                    let data = r.data
                    this.loadingBtn = false
                    this.saveMsj    = true
    
                    if(data.response){
                        this.saveColor = 'success'
                        this.modal = false
                        this.$emit('success')
                    }else{
                        this.saveColor = 'error'
                    }

                    this.saveText = this.$t(data.message)

                }).catch(() => {

                    this.loadingBtn = false
                    this.saveMsj    = true
                    this.saveColor  = 'error'
                    this.saveText   = this.$t('Ha ocurrido un error')
                })
            })
        }
    }
}

</script>