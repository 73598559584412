<template lang="pug">
    div
        v-snackbar(
        :timeout="30000",
        :top="true"
        :right="true"
        :color="alertColor"
        v-model="alertMsj")
            | {{ alertText }}
            v-btn(flat, dark, @click.native="alertMsj = false") {{ $t('cerrar') }}
        v-dialog(persistent lazy max-width="900px" v-model="modal")
            v-form
                v-card
                    v-card-title {{ $t('Importar usuarios') }}
                    v-card-text
                        p {{ $t('Busca la red para importar los usuarios') }}
                        v-select.mb-3(
                            v-model="networkid"
                            :items='networks'
                            item-text="name"
                            item-value="uuid"
                            :label='$t("Red")'
                            append-icon="keyboard_arrow_down" 
                            autocomplete
                            :filter="vFilter"
                        )
                        p {{ $t('Para importar los usuarios siga las instrucciones:') }}
                        v-btn.mt-2.primary(@click="downloadTemplate") {{ $t('Descargar plantilla') }}
                        h4.mt-4 {{ $t('Nombre de los campos') }}
                        v-data-table(:headers='headers' :items="items" hide-actions)
                            template(slot='headers' slot-scope='props')
                                tr
                                    th(v-for='(header, i) in props.headers' :key='i' :align="header.align")
                                        span.subheading {{ $t(header.text) }}
                            template(slot='items' slot-scope='props')
                                tr
                                    td {{ props.item.id }}
                                    td {{ props.item.name }}
                                    td {{ props.item.required }}
                                    td {{ props.item.instructions }}
                        h4.mt-4.mb-2 {{$t('Subir archivo')}}
                        input(type='file' @change="fileChangeImport")
                    v-card-actions
                        .btns.btns-right
                            v-btn(color="secondary" small @click="modal=false") {{$t('Aceptar')}}
                            v-btn(color="secondary" :loading="loadingImport" :disabled="loadingImport" small @click="startImport") {{$t('Iniciar importación')}}
</template>
<script>

import fn from 'mixins/fn'
import config from 'src/config'

export default {
    mixins: [fn],
    data() {
        return {
            modal: false,
            loadingImport: false,
            alertMsj  : false,
            alertColor: '',
            alertText: '',
            networkid: '',
            headers: [
                {
                    text: 'ID',
                    align: 'left',
                    sortable: false
                },
                {
                    text: 'Nombre(s)',
                    align: 'left',
                    sortable: false
                },
                {
                    text: 'Requerido',
                    align: 'left',
                    sortable: false
                },
                {
                    text: 'Instrucciones',
                    align: 'left',
                    sortable: false
                }
            ],
            networks: [],
            importFile: '',
            networks: [],
        }
    },
    computed: {
        items(){
            return [
                {
                    id      : 'names',
                    name    : this.$t('Nombres'),
                    required: this.$t('Si'),
                    instructions: this.$t('Nombre del usuario')
                },
                {
                    id      : 'surname',
                    name    : this.$t('Apellido'),
                    required: this.$t('Si'),
                    instructions: this.$t('Apellido del usuario')
                },
                {
                    id      : 'surname2',
                    name    : this.$t('Segundo apellido'),
                    required: this.$t('No'),
                    instructions: this.$t('Segundo apellido del usuario')
                },
                {
                    id      : 'country',
                    name    : this.$t('País'),
                    required: this.$t('Si'),
                    instructions: 'El nombre del país debe ser mismo registrado en wappid'
                },
                {
                    id      : 'phone',
                    name    : this.$t('Teléfono'),
                    required: this.$t('Si'),
                    instructions: 'Número de teléfono sin código de país'
                }
            ]
        },
    },
    created(){
        this.fetchNetwork()
    },
    methods: {
        fetchNetwork(){

            this.networks = []
            return new Promise((resolve) => {
                this.$api(this, (xhr) => {
                    xhr.get('/network', {
                        params: {
                            list: 1
                        }
                    }).then((r) => {

                        let data = r.data
                        if(data.response){
                            this.networks = data.data
                        }
                        resolve()

                    }).catch(() => {
                        resolve()
                    })
                })
            })
        },
        async open() {
            this.loadingImport = false
            this.importFile = ''
            this.networkid = ''
            this.modal  = true
        },
        fileChangeImport(e) {

            var files = e.target.files || e.dataTransfer.files
            if (!files.length) return

            this.importFile = files[0]
        },
        startImport(){

            let post = new FormData()
            
            post.append('upload', this.importFile)
            post.append('networkid', this.networkid)

            this.loadingImport = true
            this.$api(this, (xhr) => {
                xhr.post('/user/import', post).then((r) => {

                    let data = r.data
                    this.loadingImport = false
                    this.alertMsj = true

                    if(data.response){
                        this.alertColor = 'success'
                        this.alertText = data.message
                        this.modal = false
                        this.$emit('success')
                    }else{
                        this.alertColor = 'error'
                        this.alertText = data.message
                    }
                }).catch(() => {
                    this.loadingImport = false
                })
            })
        },
        downloadTemplate(){
            window.open(
                config.apiurl + 'import_user.xlsx',
                '_blank'
            )
        },
    }
}
</script>