<template lang="pug">
div
    v-snackbar(
    :timeout="3000"
    :top="true"
    :right="true"
    :color="saveColor"
    v-model="saveMsj")
        | {{ saveText }}
        v-btn(flat dark @click.native="saveMsj = false") {{ $t('cerrar') }}
    v-dialog(lazy max-width="900px" v-model="modal" persistent)
        v-card
            v-card-title 
                .btns.btns-right
                    v-btn(icon color="secondary" small @click.native="modal=false")
                        v-icon close
                h3.full-width {{ $t('Enviar mensaje de promoción a la red') }}
            v-card-text
                template(v-if="preload")
                    h3 {{$t('Cargando')}}...
                template(v-else)
                    v-form
                        v-layout(row wrap)
                            v-flex(xs12 sm6)
                                v-select(:label='$t("Seleccione una plantilla")' clearable v-model="model.templateid" :items="templates" item-text="template_name" item-value="uuid")
                            template(v-if="loadingTemplate")
                                v-flex(xs12)
                                    h3 {{$t('Cargando')}}...
                            template(v-else-if="model.templateid")
                                v-flex(xs12 sm6)
                                    h4.my-2 {{$t('Vista previa')}}
                                    whatsapp-preview(:text="model.message")
            v-card-actions(v-if="!preload")
                .btns.btns-right
                    v-btn(color="secondary" small @click="modal = false") {{ $t('Cancelar') }}
                    v-btn(v-if="!preload && model.templateid && shareUrl" color="primary" :loading="loadingBtn" :disabled="loadingBtn" small @click="send") {{$t('Enviar')}}
</template>

<script>
export default {
    data() {
        return {
            modal     : false,
            saveMsj   : false,
            saveColor : '',
            saveText  : '',
            preload   : true,
            current: {},
            templates: [],
            modelSchema: {
                message: ''
            },
            model: {},
            loadingTemplate: false,
            loadingBtn: false,
            shareUrl: ''
        }
    },
    watch: {
        async 'model.templateid'() {

            this.shareUrl = ''
            if (!this.model.templateid) {
                this.model = this._.cloneDeep(this.modelSchema)
                return
            }

            this.loadingTemplate = true
            await this.fetchInviteText()
            this.loadingTemplate = false
        }
    },
    methods: {
        async open(item) {

            this.preload = true

            this.current = this._.cloneDeep(item)
            this.model   = this._.cloneDeep(this.modelSchema)
            this.modal = true
            this.shareUrl = ''
            this.templates = []

            await this.fetchTemplates()
            
            this.preload = false
        },
        fetchTemplates(){
            return new Promise((resolve) => {

                this.$api(this, (xhr) => {
                    xhr.get('/whatsapp/invite/templates', {
                        params: { networkid: this.current.networkid }
                    }).then((r) => {

                        let data = r.data
                        if(data.response){
                            this.templates = [{ 'uuid': 1, 'template_name': this.$t('Por defecto') }].concat(data.data)
                            resolve()
                        } else {
                            this.templates = [{ 'uuid': 1, 'template_name': this.$t('Por defecto') }]
                            resolve()
                        }
                    }).catch(() => {
                        resolve()
                    })
                })
            })
        },
        fetchInviteText(){
            return new Promise((resolve) => {

                this.$api(this, (xhr) => {
                    xhr.get('/whatsapp/invite', {
                        params: {
                            networkid: this.current.networkid,
                            userid: this.current.uuid,
                            templateid: this.model.templateid
                        }
                    }).then((r) => {

                        let data = r.data
                        if (data.response) {
                            this.model.message = data.data.preview
                            this.shareUrl = data.data.url
                            resolve()
                        }else{
                            resolve()
                        }

                    }).catch(() => {
                        resolve()
                    })
                })
            })
        },
        send() {

            this.saveMsj = true  
            this.saveColor = 'success'
            this.saveText = this.$t('Seras redireccionado al enlace de promoción de red')

            window.open(this.shareUrl, 'blank')
        }
    }
}

</script>