<template lang="pug">
    div
        v-snackbar(
            :top="true"
            :right="true"
            :color="alertColor"
            v-model="alert")
                | {{ alertText }}
                v-btn(flat dark @click.native="alert = false") {{$t('cerrar')}}
        v-dialog(v-model="modal" max-width="600")
            v-form
                v-card
                    v-card-title {{ $t('Asignar referidor') }}
                    v-card-text
                        v-layout(row wrap)
                            p: i {{ $t('Busca el referidor para asociar a este usuario (Puedes buscar por correo electrónico y nombres)') }}
                            v-flex(xs12)
                                label {{ $t('Referidor') }}
                                multiselect.mt-2(
                                    :append-to-body="true"
                                    v-model="model.referid"
                                    :options="refers"
                                    :placeholder="$t('Buscar referidor')"
                                    @search-change="searchRefers"
                                    :searchable="true"
                                    :loading="loadingRefer"
                                    label="names"
                                    track-by="uuid"
                                    :internal-search="false"
                                    :show-no-results="false"
                                    :show-labels="false"
                                    :allow-empty="false"
                                    :clear-on-select="false"
                                    :hide-selected="false"
                                )
                                    template(slot="option" slot-scope="props")
                                        .option__desc
                                            span.d-block {{ props.option.names }}
                                            small.d-block {{ props.option.email }}
                                    span(slot="noOptions") {{ $t('Lista vacia') }}
                    v-card-actions
                        .btns.btns-right
                            v-btn(color="secondary" small @click.native="modal = false") {{ $t('Cancelar') }}
                            v-btn(color="primary" large @click.native="deleteRef" :loading="loaderDelete") {{ $t('Eliminar') }}
                            v-btn(color="primary" large @click.native="save" :loading="loader") {{ $t('Guardar') }}
</template>
<script>

    import Multiselect from 'vue-multiselect'
    export default {
        data() {
            return {
                modal: false,
                modelSchema: {
                    userid  : '',
                    referid : ''
                },
                model        : {},
                alert        : false,
                alertText    : '',
                alertColor   : '',
                loader       : false,
                loaderDelete : false,
                loadingRefer : false,
                refers       : []
            }
        },
        methods: {

            open(userid){

                this.model = this._.cloneDeep(this.modelSchema)
                this.loader = false

                this.modal = true
                this.model.userid = userid
            },
            save(){

                if(this.model.referid == '' || !Object.keys(this.model.referid).length){

                    this.alert      = true
                    this.alertColor = 'error'
                    this.alertText  = this.$t('Seleccione un usuario')
                    return
                }

                this.loader = true
                this.$api(this, (xhr) => {
                    xhr.post('/referral/assign', this.$qs.stringify({
                        userid  : this.model.userid,
                        referid : this.model.referid.uuid
                    })).then((r) => {

                        let data = r.data
                        this.loader = false
                        this.alert  = true
                            
                        if(data.response){

                            this.alertColor = 'success'
                            this.alertText  = this.$t(data.message)
                            
                            this.modal = false
                            this.$emit('success')
                        }else{
                            this.alertColor = 'error'
                            this.alertText  = this.$t(data.message)
                        }
                    }).catch(() => {
                        this.loader = false
                        this.alert  = true
                        this.alertColor = 'error'
                        this.alertText  = this.$t('Ha ocurrido un error')
                    })
                })
            },
            deleteRef(){

                this.loaderDelete = true
                this.$api(this, (xhr) => {
                    xhr.delete('/referral/delete', {
                        params: {
                            userid: this.model.userid
                        }
                    }).then((r) => {

                        let data = r.data
                        this.loaderDelete = false
                        this.alert  = true
                            
                        if(data.response){

                            this.alertColor = 'success'
                            this.alertText  = this.$t(data.message)
                            
                            this.modal = false
                            this.$emit('success')
                        }else{
                            this.alertColor = 'error'
                            this.alertText  = this.$t(data.message)
                        }
                    }).catch(() => {
                        this.loaderDelete = false
                        this.alert  = true
                        this.alertColor = 'error'
                        this.alertText  = this.$t('Ha ocurrido un error')
                    })
                })
            },
            searchRefers(query){
                
                if(query.length < 3){
                    return
                }

                this.loadingRefer = true
                this.$api(this, (xhr) => {
                    xhr.get('/referral/available', {
                        params: {
                            userid: this.model.userid,
                            query
                        }
                    }).then((r) => {

                        let data = r.data
                        this.loadingRefer = false
                        this.refers = data.response ? data.data : []

                    }).catch(() => {
                        this.refers = []
                        this.loadingRefer = false
                    })
                })
            }
        },
        components: {
            Multiselect
        }
    }

</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>