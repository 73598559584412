<template lang="pug">
    div
        v-snackbar(
        :timeout="3000"
        :top="true"
        :right="true"
        :color="alertColor"
        v-model="alertMsj")
            | {{ alertText }}
            v-btn(flat dark @click.native="alertMsj = false") {{ $t('cerrar') }}
        v-dialog(lazy max-width="900px" v-model="highlight")
            v-card
                v-card-title 
                    .btns.btns-right
                        v-btn(icon color="secondary" small @click.native="highlight=false")
                            v-icon close
                    h3.full-width {{ $t('Destacar redes') }}
                v-card-text
                    template(v-if="preload")
                        h3 {{$t('Cargando')}}...
                    template(v-else)
                        v-layout(row wrap)
                            p: i {{ $t('Destacar redes en la página de inicio de la red') }}
                            v-flex(xs12)
                                v-select.mb-3(
                                    v-model="model.network"
                                    :items='networks'
                                    item-text="name"
                                    item-value="uuid"
                                    :label='$t("Red")'
                                    append-icon="keyboard_arrow_down" 
                                    autocomplete
                                    :filter="vFilter"
                                    :return-object="true"
                                )
                                .btns.btns-right
                                    v-btn(color="secondary" small @click="addNetwork") {{ $t('Agregar red') }}
                            v-flex(xs12)
                                draggable(tag="v-list" :list.sync="model.networks" :options="colorsDrag")
                                    v-list-tile(v-for="(network, i) in model.networks" :key="'network'+i")
                                        v-list-tile-action
                                            v-icon.opts--handle.cur-p.primary--text drag_indicator
                                        v-list-tile-content {{ network.name }}
                                        v-list-tile-action(v-if="$root.profile.type == 0 ? true : (networks.some(n => n.uuid == network.uuid))")
                                            v-icon(@click="model.networks.splice(i, 1)") delete
                v-card-actions
                    .btns.btns-right
                        v-btn(color="secondary" small @click.native="highlight = false") {{ $t('Cancelar') }}
                        v-btn(color="primary" large @click.native="save" :loading="loadingBtn") {{ $t('Guardar') }}
</template>

<script>

import config from 'src/config'
import fn from 'mixins/fn'
import draggable from 'vuedraggable'

export default {
    components: {
        draggable
    },
    mixins: [fn],
    data(){
        return {
            highlight : false,
            alertMsj   : false,
            alertColor : '',
            alertText  : '',
            preload    : true,
            current    : {},
            loadingBtn : false,
            networks   : [],
            modelSchema: {
                network  : {},
                networks : []
            },
            model: {},
            optsDrag: {
                group    : 'opts',
                draggable: '.opts--drag',
                handle   : '.opts--handle'
            }
        }
    },
    computed: {
        imgurl(){
            return config.imgurl
        },
        uploadurl(){
            return config.uploadurl
        }
    },
    methods: {

        fetchNetwork(){

            this.networks = []
            return new Promise((resolve) => {
                this.$api(this, (xhr) => {
                    xhr.get('/network', {
                        params: {
                            list    : 1,
                            exclude : JSON.stringify([this.current.networkid])
                        }
                    }).then((r) => {

                        let data = r.data
                        if(data.response){
                            this.networks = data.data
                        }

                        resolve()

                    }).catch(() => {
                        resolve()
                    })
                })
            })
        },

        addNetwork(){
                
            let exists = this.model.networks.some(s => s.uuid == this.model.network.uuid)
            if(!exists){
                this.model.networks.push(this.model.network)
            }

            this.model.network = {}
        },

        async open(item){

            this.preload = true

            this.current = this._.cloneDeep(item)
            this.model   = this._.cloneDeep(this.modelSchema)

            await this.$nextTick()

            this.highlight = true
            await this.fetchNetwork()
            await this.fetch()

            this.preload = false
        },

        fetch(){

            this.model.networks = []
            return new Promise((resolve) => {

                this.$api(this, (xhr) => {
                    xhr.get('/highlight-network/user', {
                        params: {
                            userid: this.current.uuid
                        }
                    }).then((r) => {
                        
                        let data = r.data
                        if(data.response){
                            this.model.networks = data.data
                        }
                        resolve()

                    }).catch(() => {
                        resolve()
                    })
                })
            })
        },

        save(){

            this.loadingBtn = true
            this.$api(this, (xhr) => {
                xhr.post('/highlight-network/user', this.$qs.stringify({
                    networks : JSON.stringify(this.model.networks.map(n => n.uuid)),
                    userid   : this.current.uuid
                })).then((r) => {

                    let data = r.data
                    this.loadingBtn = false
                    this.alertMsj   = true
                        
                    if(data.response){

                        this.alertColor = 'success'
                        this.alertText  = this.$t(data.message)
                        
                        this.highlight = false
                        this.$emit('success')
                    }else{
                        this.alertColor = 'error'
                        this.alertText  = this.$t(data.message)
                    }
                }).catch(() => {
                    this.loadingBtn = false
                })
            })
        }
    }
}

</script>